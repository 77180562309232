<template>
    <div class="color-txt">
        <div class="">
            <s-form>
                <div class="">
                    <div class="row">
                        <div class="col-lg-12">
                            <h3 class="text-primary mb-5 font-poppins-semibold ">Semen Quarantine</h3>
                        </div>
                        <div class="col-lg-2 pr-0">
                            <validated-date-picker class="c-input-datepicker fs-lg--1 field-required" format="DD-MM-YYYY"
                                                   placeholder="Date of Production" @input="loadBulls" :rules="{required:true}"
                                                   v-model="model.production_date"></validated-date-picker>

                        </div>
                    </div>
                    <div class="row" v-if="model.production_date">
                        <div class="col-lg-12">

                            <div class="card pl-0 pr-0 pt-0 pb-0 w-60r">

                                <table style="width:100%" class="c-table-1">
                                    <th class="text-primary">Bull No</th>
                                    <th class="text-primary">Bull Name</th>
                                    <th class="text-primary">Ejaculate No</th>
                                    <th class="text-primary">Dose</th>
                                    <tr class="mt-3" v-if="loadingData">
                                        <td colspan="4">Please wait while fetching the data...</td>
                                    </tr>
                                    <tr v-for="(item, i) in bullsList" :key="i" @click="clickedBull(i)" style="cursor: pointer">
                                        <td>{{ item.bull.reg_no }}</td>
                                        <td>{{ item.bull.bull_name }}</td>
                                        <td>{{ item.ejaculation_no }}</td>
                                        <td>{{ item.dose }}</td>

                                    </tr>
                                </table>

                            </div>
                        </div>
                        <div class="col-lg-12">
                            <semen-quaratinetwo-fields
                                :index="selectedIndex"
                                :data="bullsList"
                                :prod="model.production_date"
                                ref="component" @data="addItem"></semen-quaratinetwo-fields>
                        </div>
                    </div>
                </div>
            </s-form>
        </div>
    </div>
</template>

<script>
import SemenQuaratinetwoFields from '../../components/SemenQuaratineTwo/SemenQuaratinetwoFields';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'SemenQuarantine',
    components: { SemenQuaratinetwoFields },

    data () {
        return {
            URL: urls.semenQuarantine.addEdit,
            model: {
                production_date: null
            },
            bullsList: [],
            selectedIndex: '',
            loadingData: false
        };
    },
    methods: {
        async loadBulls () {
            this.loadingData = true;
            const response = await axios.form(urls.semenQuarantine.PendingListView, {
                production_date: this.model.production_date
            });
            this.bullsList = response.data.data;
            this.loadingData = false;
        },
        clickedBull (index) {
            this.selectedIndex = index;
            console.log('this.selectedIndex:>>>>>>>>>>>:', this.selectedIndex);
        },

        async addItem (data) {
            console.log('data', data);
            try {
                this.$refs.component.loadingTure();

                if (data.fsd_storage_type === data.ibr_storage_type &&
                    data.fsd_storage_name === data.ibr_storage_name &&
                    data.fsd_location === data.ibr_location &&
                    data.fsd_position === data.ibr_position &&
                    data.fsd_storage_type !== '' &&
                    data.fsd_storage_name !== '' &&
                    data.fsd_location !== '' &&
                    data.fsd_position !== '') {
                    this.$refs.component.errorMessage = true;
                    this.$refs.component.loadingFalse();
                    return;
                } else {
                    this.$refs.component.errorMessage = false;
                }

                const response = await axios.form(this.URL, data);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.$refs.component.loadingFalse();
                    await this.loadBulls();
                    this.$router.push({ path: '/semen-station/' });
                } else {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.$refs.component.loadingFalse();
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.$refs.component.loadingFalse();
            }
        }
    }

};
</script>

<style scoped>

</style>
